import {useAuthStore} from "~/stores/auth";

export default defineNuxtRouteMiddleware(async () => {
  const auth = useAuthStore()

  // Redirect to dashboard if user is logged in
  if (auth.user) {
    return navigateTo("/dashboard", { replace: true })
  }
})
